@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
    --background: #ffffff;
    --foreground: #ffffff;

    --base-100: #f6f6f6; /* Surfaces */
    --base-200: #f1f1f1; /* Surfaces */
    --base-300: #e8e8e8; /* Borders */
    --base-400: #dcdcdc; /* Borders */
    --base-500: #b9b9b9; /* Disabled text */
    --base-600: #8b8b8b; /* Tertiary text & Icons */
    --base-700: #6b6b6b; /* Secondary text */
    --base-800: #5d5d5d; /* Icons */
    --base-900: #171717; /* Primary text */ /* BASE COLOR */

    --secondary-100: #eff3f7;
    --secondary-200: #d7e2ec;
    --secondary-300: #afc5d9;
    --secondary-400: #87a8c5;
    --secondary-500: #5f8bb2; /* BASE COLOR */
    --secondary-600: #567da0;
    --secondary-700: #4c6f8e;
    --secondary-800: #3e5a74;
    --secondary-900: #304659;

    --primary-200: #e0ebff;
    --primary-300: #bed4ff;
    --primary-400: #6095fd;
    --primary-500: #4584ff;
    --primary-600: #0057ff; /* BASE COLOR */
    --primary-700: #0049d6;
    --primary-800: #0040bb;
    --primary-900: #00349b;

    --destructive-200: #feeae9;
    --destructive-300: #fdcbc8;
    --destructive-400: #fc9891;
    --destructive-500: #fb645b;
    --destructive-600: #f93124; /* BASE COLOR */
    --destructive-700: #e02c20;
    --destructive-800: #c7271d;
    --destructive-900: #a22017;

    --warning-200: #fff5e5;
    --warning-300: #ffe5bf;
    --warning-400: #ffcc80;
    --warning-500: #ffb340;
    --warning-600: #ff9900; /* BASE COLOR */
    --warning-700: #e58a00;
    --warning-800: #cc7a00;
    --warning-900: #a66300;

    --affirmative-200: #eaf8f2;
    --affirmative-300: #caeedf;
    --affirmative-400: #95debf;
    --affirmative-500: #60cd9f;
    --affirmative-600: #2bbc7f; /* BASE COLOR */
    --affirmative-700: #27a972;
    --affirmative-800: #229666;
    --affirmative-900: #1c7a53;

    --event-header-height: 4rem;
    --event-footer-height: 5.125rem;
}

@media (prefers-color-scheme: dark) {
    :root {
        --text-base-color: 255, 255, 255;
    }
}

body {
    color: rgb(var(--base-900));
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}

.react-joyride__spotlight {
    border: 2px solid #003bad;
    box-shadow: 0px 0px 12px 0px #0057ff4d;
}
